/* eslint-disable no-restricted-syntax */
import EventEmitter from 'wolfy87-eventemitter';

import './utils/polyfills';
import { log, logMethodCall, getConsoleStyle } from './utils/debug';
import { domReady } from './utils/dom';
import CleverPushApi from './api';
import request from './utils/request';
import { translate as utilsTranslate, detectLanguage } from './utils/translate';
import customCssOverrides from './const/customCssOverrides';
import Event from './const/event';
import CleverPushError from './error/CleverPushError';
import {
  browser,
  os,
  device,
  browserVersion,
  browserSubVersion,
  isHost,
  isPopup,
  isPopupUnsubscribe,
  isIframe,
  isBrowser,
  supportsPush,
  isEdge,
  isFirefox,
  inEdgeUnblockVersionRange,
  isWebView,
} from './utils/env';
import {
  INT_RADIX,
  TRUE_STRING,
  PANEL_TABS,
  POST_MESSAGE_ALLOWED_METHODS,
  WEB_BANNER,
  OPT_INS,
  TIME_UNITS,
  MILLISECOND_UNIT,
  CLEVERPUSH_EXIT_INTENT_SHOWN,
  SESSION_BANNER_DISPLAY_KEY,
  MULTIPLE_BANNER_BEHAVIOUR
} from './const/common';
import { triggerOptIn } from './optIn';

export default class CleverPush extends EventEmitter {
  constructor() {
    super();

    this.bell = null;
    this.config = {};
    this.webBanners = [];
    this.multipleBannerOncePerImpressionShown = false;
    this.log = log;
    this.initialized = false;
    this.initCalled = false;
    this.initFailed = false;
    this.initError = null;
    this.api = null;
    this.subscriptionManager = null;
    this.version = typeof VERSION !== 'undefined' ? VERSION : '';
    this.isMobile = os.name === 'Android' || os.name === 'iOS' || device.type === 'mobile';
    this.triggerOptIn = triggerOptIn.bind(this);
  }

  init(configParam, callbackParam) {
    logMethodCall('init');
    log.info('Browser:', browser.name, browserVersion);

    const callback = (err, successParam) => {
      const success = typeof successParam !== 'undefined' ? successParam : !err;

      if (typeof callbackParam === 'function') {
        callbackParam(err, success);
      } else if (err) {
        if (err.warn || err.reason === 'unsupported-browser' || err.reason === 'private-mode' || err.reason === 'already-initialized' || err.reason === 'init-already-called') {
          log.warn(err.message);
        } else {
          log.error(err);
        }
      }

      if (typeof window.cleverPushInitCallback === 'function') {
        window.cleverPushInitCallback(err, success);
      }

      if (err) {
        if (err.reason !== 'already-initialized' && err.reason !== 'init-already-called') {
          this.initFailed = true;
          this.initError = err;
        }

        this.trigger(Event.INITIALIZATION_FAILED);
      } else {
        this.initFailed = false;
      }
    };

    if (this.initCalled) {
      callback(new CleverPushError('Init was already called, please only call init() once.', 'init-already-called'), false);
    } else {
      this.initCalled = true;

      // message listener for iframes
      window.addEventListener('message', (event) => {
        if (
          event.data
          && typeof event.data === 'object'
          && event.data.type === 'cleverpush'
          && event.data.method
          && POST_MESSAGE_ALLOWED_METHODS.includes(event.data.method)
          && event.data.arguments
          && this[event.data.method]
        ) {
          this[event.data.method](...event.data.arguments);
        }
      });

      if (typeof configParam !== 'object') {
        callback(new CleverPushError('Required parameter `config` not given.', 'invalid-config'));
      } else if (!configParam.channelId && !configParam.loadConfigFromParent) {
        callback(new CleverPushError('Required attribute `channelId` in `config` not given.', 'invalid-config'));
      } else if (this.initialized) {
        callback(new CleverPushError('CleverPush SDK was already initialized.', 'already-initialized'));
      } else {
        this.api = new CleverPushApi(configParam.channelId);

        if (configParam.apiEndpoint) {
          this.api.endpoint = configParam.apiEndpoint;
        }

        if (!configParam.env && window.cleverPushConfig && window.cleverPushConfig.env) {
          configParam.env = window.cleverPushConfig.env;
        } else if (!configParam.env && window.cleverpushConfig && window.cleverpushConfig.env) {
          configParam.env = window.cleverpushConfig.env;
        }

        const initSubscriptionManager = () => {
          let protocol = window.location.protocol;
          let hostname = window.location.hostname;
          try {
            protocol = window.parent.location.protocol;
            hostname = window.parent.location.hostname;
          } catch (error) {
            log.info('error reading parent location', error);
          }

          let importFile;
          if (this.browserType === 'safari') {
            importFile = 'safariManager';
          } else if (this.browserType === 'facebook') {
            importFile = 'facebookManager';
          } else if (this.browserType === 'wallet') {
            importFile = 'walletManager';
          } else if (this.browserType === 'urlSession') {
            importFile = 'urlSessionManager';
          } else if (isPopup(configParam) || configParam.env === 'POPUP') {
            importFile = 'httpPopupManager';
          } else if (isIframe(configParam)) {
            importFile = 'httpIframeManager';
          } else if (this.config.ownDomain && (protocol === 'https:' || hostname === 'localhost')) {
            importFile = 'httpsManager';
          } else {
            importFile = 'httpHostManager';
          }

          return new Promise((resolve) => {
            import(`./subscription/${importFile}`).then(({ default: SubscriptionManager }) => {
              this.subscriptionManager = new SubscriptionManager(this.config, this.api, this.trigger.bind(this));
              resolve();
            });
          });
        };

        if ((configParam.env !== 'POPUP' && isHost(configParam)) || configParam.env === 'PREVIEW' || configParam.env === 'WIDGET') {
          const loadConfigPromise = new Promise((resolve, reject) => {
            const confirmAlertTestsEnabled = configParam.confirmAlertTestsEnabled && configParam.confirmAlertTests?.length;
            let confirmAlertAutoRegisterTest = false;
            if (
              confirmAlertTestsEnabled
              && configParam.confirmAlertTests.length === 2
              && configParam.confirmAlertTests.find((test) => (
                typeof test.config?.autoRegister !== 'undefined' && test.config?.autoRegister !== configParam.autoRegister
              ))
            ) {
              // for opt-in split tests which contain an "autoRegister: false" variant we do not want to load the confirm alert test ID dynamically from the API, because the test might get stuck with it.
              confirmAlertAutoRegisterTest = true;
            }

            const bypassConfigCache = location.search?.includes('cleverPushWebBannerId=');
            if (
              typeof fetch !== 'undefined'
              && (
                typeof configParam.loadConfig === 'undefined'
                || configParam.loadConfig
                || (confirmAlertTestsEnabled && !confirmAlertAutoRegisterTest)
                || bypassConfigCache
              ) && configParam.env !== 'PREVIEW'
            ) {
              this.api.getChannelConfig(configParam.confirmAlertTestsEnabled, bypassConfigCache).then((channelConfig) => {
                resolve(channelConfig);
              }).catch(reject);
            } else if (configParam.loadConfigUrl) {
              request(configParam.loadConfigUrl).then((json) => {
                if (json) {
                  resolve(json);
                } else {
                  resolve({});
                }
              }).catch(reject);
            } else {
              resolve({});
            }
          });

          if (configParam.apiEndpoint) {
            this.api.endpoint = configParam.apiEndpoint;
          }

          loadConfigPromise.then((channelConfig) => {
            this.config = {
              autoRegister: true,
              alertTimeout: 0,
              alertMinimumVisits: 0,
              loadIframe: true,
              trackSessions: true,
              ...channelConfig,
              ...configParam,
              webBanners: channelConfig.webBanners || configParam.webBanners,
              confirmAlertTests: channelConfig.confirmAlertTests || configParam.confirmAlertTests,
            };

            this.configLoaded = true;
            this.trigger(Event.CONFIG_AVAILABLE);

            this.initWebpackPublicPath();

            if (this.config.enableRestrictOptInDomain && this.config.domain) {
              if (!this.config.domain.includes(location.hostname)) {
                callback(new CleverPushError(`This channel has enabled Opt-in domain restrictions. (${location.hostname} is not included in allowed domains (${this.config.domain}))`, 'enableRestrictOptInDomain'));
                return;
              }
            }

            if (configParam.alertLocalization) {
              // merge
              this.config.alertLocalization = { ...channelConfig.alertLocalization, ...configParam.alertLocalization };
            }

            if (typeof this.config.channelId === 'undefined' || typeof this.config.channelName === 'undefined') {
              callback(new CleverPushError('`channelId` or `channelName` not found in config.', 'invalid-config'));
              return;
            }

            if (typeof self.cleverPushConfig === 'object') {
              this.config = Object.assign(this.config, self.cleverPushConfig);
            } else if (typeof self.cleverpushConfig === 'object') {
              this.config = Object.assign(this.config, self.cleverpushConfig);
            }

            log.debug('Config:', this.config);

            if (this.config.apiEndpoint) {
              this.api.endpoint = this.config.apiEndpoint;
            }
            if (this.config.regionEnabled) {
              this.api.regionEnabled = true;
            }

            if (isPopupUnsubscribe()) {
              this.config.autoRegister = false;
            }

            if (!this.config.facebookAppId) {
              this.config.facebookAppId = '436333683366106';
            }

            if (typeof URLSearchParams !== 'undefined' && location.search && location.search.length) {
              const params = new URLSearchParams(location.search.slice(1));
              if (params.get('confirmAlertTestId')) {
                this.config.confirmAlertTestId = params.get('confirmAlertTestId');
                this.api.setConfirmAlertTestId(this.config.confirmAlertTestId);
              }

              if (params.get('cpId')) {
                try {
                  sessionStorage.setItem('cleverpush-url-session', TRUE_STRING);
                  sessionStorage.setItem('cleverpush-subscription-id', params.get('cpId'));
                } catch (error) {
                  log.warn('Error setting URL-session', error);
                }
              }
            }

            try {
              if (sessionStorage.getItem('cleverpush-url-session')) {
                this.urlSession = true;
              }
            } catch (error) {
              log.info('Error getting url-session key from storage', error);
            }

            // only set cleverpush-referrer if really needed
            try {
              if (this.config.trackOptInReferrers || (this.config.confirmAlertFilters || []).find((f) => f.type === 'referrer') || (this.config.bounceEnabled && (this.config.bounceFilters || []).find((f) => f.source === 'direct'))) {
                if (!sessionStorage.getItem('cleverpush-referrer') && document.referrer) {
                  sessionStorage.setItem('cleverpush-referrer', document.referrer.split('?')[0]);
                }
              }
              this.referrer = sessionStorage.getItem('cleverpush-referrer');
            } catch (e) {
              log.debug(e);
            }

            const supportsPushOrFbMessenger = () => new Promise((resolve, reject) => {
              const catchError = (compatibilityError) => {
                if (compatibilityError.reason !== 'unsupported-browser') {
                  reject(compatibilityError);
                  return;
                }

                if (
                  this.urlSession
                  && !configParam.forceChannelId
                ) {
                  import('./utils').then(({ initHideUnsupported }) => {
                    initHideUnsupported();
                  });
                  import('./utils').then(({ setUrlSessionConfig }) => {
                    setUrlSessionConfig(configParam, this.config, this.api);
                    resolve('urlSession');
                  });
                  return;
                }

                if (
                  this.config.facebookAppId
                  && this.config.facebookPageId
                  && this.config.facebookCheckboxEnabled
                  && !isWebView()
                ) {
                  import('./utils/setFacebookChannelConfig').then(({ setFacebookChannelConfig }) => {
                    setFacebookChannelConfig(this, configParam);
                    resolve('facebook');
                  });
                  import('./utils').then(({ initHideUnsupported }) => {
                    initHideUnsupported();
                  });
                  return;
                }

                if (
                  this.config.multiChannels
                  && this.config.multiChannels.walletChannel
                  && (
                    (this.config.walletPasses || []).length
                    || (
                      this.config.confirmAlertTestsEnabled
                      && this.config.confirmAlertTestId
                      && (this.config.confirmAlertTests || []).find((t) => t.id === this.config.confirmAlertTestId && t.config && (t.config.walletPasses || []).length)
                    )
                  )
                  && os.name === 'iOS'
                  && !isWebView()
                  && !this.config.walletOptInDisabled
                ) {
                  if (this.config.multiChannels && this.config.multiChannels.walletChannel && this.config.multiChannels.walletChannel._id !== this.config.channelId && !configParam.forceChannelId) {
                    this.config.channelId = this.config.multiChannels.walletChannel._id;
                    this.config.alertLocalization = { ...this.config.alertLocalization, ...this.config.multiChannels.walletChannel.alertLocalization };
                    this.api.channelId = this.config.multiChannels.walletChannel._id;
                  }

                  this.config.hideNotificationBellSubscribed = true;

                  this.config.showConfirmAlert = true;
                  if (this.config.confirmAlertNativeTheme === 'cleverpush-confirm-backdrop-text') {
                    this.config.confirmAlertNativeTheme = '';
                  }

                  resolve('wallet');
                  return;
                }

                reject(compatibilityError);
              };

              if (this.urlSession) {
                import('./utils').then(({ setUrlSessionConfig }) => {
                  setUrlSessionConfig(configParam, this.config, this.api);
                });
                resolve('urlSession');
                return;
              }

              if (this.config.multiChannels && this.config.multiChannels.facebookChannel && this.config.multiChannels.facebookChannel.facebookPage && !this.config.facebookPageId) {
                this.config.facebookPageId = this.config.multiChannels.facebookChannel.facebookPage.id || this.config.multiChannels.facebookChannel.facebookPageId;
                this.config.facebookCheckboxEnabled = this.config.multiChannels.facebookChannel.facebookCheckboxEnabled;
                this.config.facebookCheckboxOnlyUnsupported = this.config.multiChannels.facebookChannel.facebookCheckboxOnlyUnsupported;
                this.config.facebookDoubleOptIn = this.config.multiChannels.facebookChannel.facebookDoubleOptIn;
              }

              supportsPush().then((browserType) => {
                if (this.config.facebookCheckboxOnlyUnsupported && this.config.facebookPageId) {
                  this.config.facebookPageId = null;
                }

                if (browserType === 'safari' && !this.config.safariWebsitePushId) {
                  catchError(new CleverPushError('Safari Web Push not set up', 'unsupported-browser'));
                  return;
                }

                this.initConfirmAlertFilters(configParam);

                // this.browserType can be set by applyConfirmAlertFilters
                resolve(this.browserType || browserType);
              }).catch(catchError);
            });

            // init stuff which does not require push capabilities
            import('./utils/bounce').then(({ initBounces }) => {
              initBounces(this);
            });
            domReady().then(() => {
              import('./multiPlatformWidget/initMultiPlatformWidgets').then(({ initMultiPlatformWidgets }) => {
                initMultiPlatformWidgets(this);
              });
            });

            if (this.config.displayChatWidget && this.config.chatWidgetPath) {
              import('./chat').then(({ initChatWidget }) => {
                initChatWidget(this.config);
              });
            }

            supportsPushOrFbMessenger().then((browserType) => {
              this.browserType = browserType;
              this.config.browserType = browserType;

              if (this.config.desktopOnly && (os.name === 'Android' || device.type === 'mobile')) {
                return;
              }

              // opt-in split-testing
              this.initConfirmAlertTests(configParam).then(() => {
                const language = detectLanguage(this.config || {});
                if (this.config.alertLocalizationTranslations && language && this.config.alertLocalizationTranslations[language]) {
                  const translation = this.config.alertLocalizationTranslations[language];
                  const translatedUrlFields = ['privacyPolicyText', 'privacyPolicyUrl', 'faqText', 'faqUrl'];
                  for (const translatedUrlField of translatedUrlFields) {
                    if (translation[translatedUrlField]) {
                      this.config[translatedUrlField] = translation[translatedUrlField];
                    }
                  }
                }

                // filter channel topics by path name
                if (this.config.channelTopics && this.config.channelTopics.length) {
                  this.config.channelTopics = this.config.channelTopics.sort((a, b) => a.sort - b.sort);
                }

                const initSdk = () => {
                  Promise.all([
                    this.waitForInit(),
                    initSubscriptionManager(),
                  ]).then(() => {
                    log.debug('Init done');
                    const isMobile = os.name === 'Android' || os.name === 'iOS' || device.type === 'mobile';
                    const loadBell = this.config.showNotificationBell && (!isMobile || !this.config.hideNotificationBellMobile);
                    const importPromises = [import('./confirm')];
                    if (loadBell) {
                      importPromises.push(import('./bell'));
                    }

                    Promise.all(importPromises).then(([{ default: Confirm }, bellResult]) => {
                      this.confirm = new Confirm(this, this.subscriptionManager);

                      this.trigger(Event.CONFIRM_AVAILABLE);
                      this.subscriptionManager.setConfirm(this.confirm);
                      if (loadBell) {
                        const { default: Bell } = bellResult;
                        this.bell = new Bell(this.config, this.subscriptionManager, this.api, this.confirm, this.trigger.bind(this), this.triggerOptIn.bind(this));
                      }

                      this.config.urlTriggeredPanelTab = this.validateUrlTriggerType(location.search.split('?cleverPushPanelTab=')[1]);
                      if (this.config.urlTriggeredPanelTab) {
                        this.triggerBellClick();
                      }

                      // TODO: ALL browsers: show confirm alert when has no cookies but has push permission
                      // this.config.showConfirmAlertResubscribe = true;

                      // applies only to Android Chrome + Safari Mac right now
                      const fullScreenOptIn = isMobile; // || os.name === 'Android' && (browser.name === 'Chrome' || browser.name === 'Firefox' || browser.name === 'Opera');

                      // force topics after opt in
                      if (
                        fullScreenOptIn
                        && this.config.channelTopics
                        && this.config.channelTopics.length
                        && !this.config.confirmAlertSelectTopicsLaterDisabled
                      ) {
                        this.config.confirmAlertSelectTopicsLater = true;
                      }

                      const isSafariMac = os.name === 'Mac OS' && browser.name === 'Safari';

                      if (this.config.env !== 'PREVIEW') {
                        if (
                          this.config.autoBalanceConfirmAlert
                        ) {
                          import('./utils').then(({ checkAutoBalanceShouldShowAlert }) => {
                            this.config.showConfirmAlert = !checkAutoBalanceShouldShowAlert(this.config.autoBalanceOptInRate, this.config.autoBalanceMinimumOptInRate);
                          });
                        }

                        if (this.config.showConfirmAlertMobile && !this.config.confirmAlertSelectTopicsLater && fullScreenOptIn) {
                          log.debug('showConfirmAlert = true 2');
                          this.config.showConfirmAlert = true;
                        }

                        // Safari 12.1+: Push notification prompting can only be done from a user gesture. (https://github.com/wicg/interventions/issues/49#issuecomment-477122163)
                        if (isSafariMac && (browserVersion > 12 || (browserVersion === 12 && browserSubVersion >= 1))) {
                          log.debug('showConfirmAlert = true 3');
                          this.config.showConfirmAlert = true;
                          this.config.isSafari_12_1 = true;
                        }

                        // Firefox 72: Push notification prompting can only be done from a user gesture
                        if (isFirefox() && browserVersion >= 72) {
                          log.debug('showConfirmAlert = true 4');
                          this.config.showConfirmAlert = true;
                        }

                        if (isEdge() && inEdgeUnblockVersionRange() && !this.config.silentPromptTutorialDisabled) {
                          this.displayUnblockTutorial();
                        }
                      }

                      if (
                        !this.config.confirmAlertHideChannelTopics
                        && !this.config.isSafari_12_1
                        && (
                          this.config.channelTopics || []).length
                        && ((fullScreenOptIn
                          && !this.config.showConfirmAlertMobile
                        )
                          || isSafariMac)
                        && !this.config.confirmAlertSelectTopicsLaterDisabled
                      ) {
                        this.config.confirmAlertSelectTopicsLater = true;
                      }

                      if (this.config.customAttributes && this.config.customAttributes.length && this.config.customAttributes.filter((attr) => !!attr.askAfterOptIn).length) {
                        this.config.confirmAlertSelectAttributesLater = true;
                      }

                      // only for special request users, will completely disable confirm alerts even if necessary
                      if (this.config.confirmAlertDisabled && this.config.showConfirmAlert) {
                        this.config.showConfirmAlert = false;
                      }

                      this.on(Event.SUBSCRIBED, (subscriptionId) => {
                        log.info('SUBSCRIBED fired');

                        if (this.bell) {
                          this.bell.reset(true);
                        }
                        if (this.confirm) {
                          this.confirm.hide();
                        }

                        // Google Analytics auto tracking
                        if (this.config.googleAnalyticsDimensionIndex) {
                          import('./utils/initGoogleAnalytics').then(({ initGoogleAnalytics }) => {
                            initGoogleAnalytics(subscriptionId, this.config.googleAnalyticsDimensionIndex);
                          });
                        }
                      });
                      this.on(Event.UNSUBSCRIBED, () => {
                        this.subscriptionManager.subscribed = false;
                        if (this.bell) {
                          this.bell.reset(false);
                        }
                      });

                      this.initWebBanners().then(() => {
                        this.initConversions();
                      });
                      this.initWidgets();
                      this.initTagButtons();
                      this.initTagSwitches();
                      this.initTopicButtons();
                      this.initPageBanners();

                      this.initTags();
                      this.initAttributes();
                      this.initTopics();
                      import('./piano').then(({ initPiano }) => {
                        initPiano(this.subscriptionManager);
                      });
                      this.initTcf();
                      this.initDuplicateSubscriptions();
                      this.initSpeechbubblePanels();

                      // check subscription status + sync every 2 days
                      this.subscriptionManager.isSubscribed().then((isSubscribed) => {
                        if (this.bell) {
                          this.bell.show(isSubscribed);
                          this.trigger(Event.BELL_READY);
                        }

                        if (isSubscribed) {
                          log.debug('main subscribed = true');
                          this.subscriptionManager.subscribed = true;

                          if (this.subscriptionManager.iframeMessenger) {
                            this.subscriptionManager.getSubscriptionId().then((subscriptionId) => {
                              this.subscriptionManager.iframeMessenger.getSubscriptionId().then((iframeSubscriptionId) => {
                                this.initAutoUnsubscribeDuplicateSubscriptions(subscriptionId, iframeSubscriptionId);
                                this.subscriptionManager.iframeMessenger.setSubscribed(subscriptionId);
                              });
                            });
                          }

                          if (this.config.googleAnalyticsDimensionIndex) {
                            this.subscriptionManager.getSubscriptionId().then((subscriptionId) => {
                              import('./utils/initGoogleAnalytics').then(({ initGoogleAnalytics }) => {
                                initGoogleAnalytics(subscriptionId, this.config.googleAnalyticsDimensionIndex);
                              });
                            });
                          }
                        }
                      });

                      // send session start+end to API
                      if (this.config.trackSessions) {
                        try {
                          this.waitForSubscription().then((subscriptionId) => {
                            let lastSession = this.subscriptionManager.storageManager.getLastSession();

                            import('./utils').then(({ checkEndSession }) => {
                              checkEndSession(lastSession, subscriptionId);
                            });

                            if (!lastSession.startedAt || sessionStorage.getItem('cleverpush-session-counted') !== TRUE_STRING) {
                              sessionStorage.setItem('cleverpush-session-counted', TRUE_STRING);

                              this.subscriptionManager.storageManager.getNotifications(1).then((notifications) => {
                                log.info('getNotifications res', notifications);
                                const data = {};
                                if (notifications && notifications.length && notifications[0] && notifications[0].id) {
                                  data.lastNotificationId = notifications[0].id;
                                }
                                if (subscriptionId) {
                                  this.api.startSession(subscriptionId, data);
                                }
                              });

                              lastSession.startedAt = new Date();
                              lastSession.visits = 0;
                            }

                            lastSession.lastInteractionAt = new Date();
                            lastSession.visits += 1;
                            this.subscriptionManager.storageManager.setLastSession(lastSession);

                            window.addEventListener('unload', () => {
                              lastSession = this.subscriptionManager.storageManager.getLastSession();

                              if (!lastSession || !lastSession.lastInteractionAt || !lastSession.startedAt || new Date(lastSession.lastInteractionAt) >= new Date(Date.now() - (60 * 60 * 30))) {
                                lastSession.lastInteractionAt = new Date();
                              }

                              import('./utils').then(({ checkEndSession }) => {
                                checkEndSession(lastSession, subscriptionId);
                              });

                              this.subscriptionManager.storageManager.setLastSession(lastSession);
                            }, false);
                          });
                        } catch (err) {
                          log.warn(err);
                        }
                      }

                      this.initConfirmAlertFilters(configParam).then(() => {
                        if (this.config.customCss) {
                          const node = document.createElement('style');
                          node.innerHTML = this.config.customCss + customCssOverrides;
                          document.body.appendChild(node);
                        }

                        if (this.config.customJs) {
                          try {
                            eval(this.config.customJs);
                          } catch (err) {
                            log.debug(err);
                          }
                        }

                        import('./optIn').then(({ autoTriggerOptIn }) => {
                          autoTriggerOptIn(this);
                        });

                        // exit intent
                        if (this.config.exitIntentOptIn) {
                          import('ouibounce').then(({ default: ouibounce }) => {
                            ouibounce(null, {
                              aggressive: true,
                              callback: () => {
                                let showExitIntent = true;
                                try {
                                  const shownStr = localStorage.getItem(CLEVERPUSH_EXIT_INTENT_SHOWN);
                                  if (shownStr) {
                                    const shownTime = parseInt(shownStr, INT_RADIX);
                                    if (!isNaN(shownTime) && shownTime + (MILLISECOND_UNIT[TIME_UNITS.DAYS]) > Date.now()) {
                                      showExitIntent = false;
                                    }
                                  }
                                } catch (error) {
                                  log.info('error reading exit intent time', error);
                                }

                                if (showExitIntent) {
                                  if (this.config.exitIntentOncePerSession) {
                                    localStorage.setItem(CLEVERPUSH_EXIT_INTENT_SHOWN, `${Date.now()}`);
                                  }
                                  this.subscriptionManager.isSubscribed().then((subscribed) => {
                                    if (!subscribed && !this.subscriptionManager.isSubscribing) {
                                      this.triggerOptIn(true);
                                    }
                                  });
                                }
                              }
                            });
                          });

                          if (this.isMobile && this.config.exitIntentForMobile) {
                            let showExitIntent = true;
                            try {
                              const shownStr = localStorage.getItem(CLEVERPUSH_EXIT_INTENT_SHOWN);
                              if (shownStr) {
                                const shownTime = parseInt(shownStr, INT_RADIX);
                                if (!isNaN(shownTime) && shownTime + (MILLISECOND_UNIT[TIME_UNITS.DAYS]) > Date.now()) {
                                  showExitIntent = false;
                                }
                              }
                            } catch (error) {
                              log.info('error reading exit intent time', error);
                            }

                            if (showExitIntent) {
                              this.subscriptionManager.isSubscribed().then((subscribed) => {
                                if (this.config.exitIntentOncePerSession) {
                                  localStorage.setItem(CLEVERPUSH_EXIT_INTENT_SHOWN, `${Date.now()}`);
                                }
                                if (!subscribed) {
                                  const shownBanner = true;
                                  const optInsConfig = this.config;
                                  import('./utils/scriptFunctions').then(({ initMobileExitIntentListeners }) => {
                                    initMobileExitIntentListeners(OPT_INS, shownBanner, optInsConfig, undefined, undefined, undefined, this);
                                  });
                                }
                              });
                            }
                          }
                        }
                      });

                      this.initialized = true;
                      this.initCalled = false;

                      callback(false, true);

                      if (isHost() && window.location.hash) {
                        if (window.location.hash.indexOf('#cleverPushScroll=') === 0) {
                          const split = window.location.hash.split('=');
                          if (split.length > 1) {
                            const scrollPercent = parseInt(split[1].split('?')[0], INT_RADIX);
                            if (scrollPercent) {
                              window.scrollTo(0, scrollPercent);
                            }
                          }
                        }
                      }

                      // check for debug URL parameter
                      if (isHost() && typeof URLSearchParams !== 'undefined') {
                        let queryParams = '';
                        if (location.hash && location.hash.indexOf('#?') === 0) {
                          queryParams += new URLSearchParams(location.hash.slice(2));
                        }
                        if (location.search && location.search.length) {
                          if (queryParams) {
                            queryParams += '&';
                          }
                          queryParams += location.search.slice(1);
                        }

                        if (queryParams) {
                          const params = new URLSearchParams(queryParams);

                          if (params.get('cleverPushBypassInactiveFollowUps') === TRUE_STRING) {
                            this.config.bypassInactiveFollowUps = true;
                          }

                          const importedSubscriptionId = params.get('cleverPushOnesignalSubscriptionId') || params.get('cleverPushAccengageSubscriptionId') || params.get('cleverPushImportedSubscriptionId') || localStorage.getItem('cleverpush-onesignal-subscription-id') || localStorage.getItem('cleverpush-imported-subscription-id');

                          if (params.get('cleverPushDebugRedirect') === TRUE_STRING) {
                            const subdomain = (this.config.subdomain || this.config.channelSubdomain);
                            if (subdomain) {
                              const status = {
                                worker: null,
                                workerScope: null,
                                notification: (window.Notification || {}).permission,
                                localStorage: {
                                  'cleverpush-subscription-id': localStorage.getItem('cleverpush-subscription-id'),
                                  'cleverpush-subscription-id-old': localStorage.getItem('cleverpush-subscription-id-old'),
                                  'cleverpush-subscription-status': localStorage.getItem('cleverpush-subscription-status'),
                                  'cleverpush-last-sync': localStorage.getItem('cleverpush-last-sync'),
                                  'cleverpush-last-worker-update': localStorage.getItem('cleverpush-last-worker-update'),
                                  'cleverpush-last-worker-version': localStorage.getItem('cleverpush-last-worker-version'),
                                  'cleverpush-visits': localStorage.getItem('cleverpush-visits'),
                                  'cleverpush-close-time': localStorage.getItem('cleverpush-close-time'),
                                  'cleverpush-deny-time': localStorage.getItem('cleverpush-deny-time'),
                                  'cleverpush-topics': localStorage.getItem('cleverpush-topics'),
                                  'cleverpush-tags': localStorage.getItem('cleverpush-tags'),
                                  'cleverpush-duplicate-channels-completed': localStorage.getItem('cleverpush-duplicate-channels-completed'),
                                }
                              };

                              if (
                                this.config.pianoEnabled
                                && this.config.pianoPublicPersistedId
                                && typeof cX !== 'undefined'
                              ) {
                                try {
                                  status.pianoSegments = cX.getUserSegmentIds({ persistedQueryId: this.config.pianoPublicPersistedId });
                                } catch (error) {
                                  log.info('Error getting Piano Segments', error);
                                }
                              }

                              const redirect = () => {
                                location.href = `https://${subdomain}.${this.config.cleverpushDomain || 'cleverpush.com'}/debug?status=${encodeURIComponent(JSON.stringify(status))}`;
                              };

                              let registrationCount = 0;
                              navigator.serviceWorker.getRegistrations().then((registrations) => {
                                if (!registrations.length) {
                                  redirect();
                                }
                                registrations.forEach((registration, i) => {
                                  const worker = this.subscriptionManager.getServiceWorker(registration);
                                  registration.pushManager.getSubscription().then((subscription) => {
                                    status[`worker${i}Url`] = worker.scriptURL;
                                    status[`worker${i}Subscription`] = JSON.stringify(subscription);

                                    registrationCount += 1;
                                    if (registrationCount >= registrations.length) {
                                      redirect();
                                    }
                                  }).catch(() => {
                                    registrationCount += 1;
                                    if (registrationCount >= registrations.length) {
                                      redirect();
                                    }
                                  });
                                });
                              }).catch(redirect);
                            }
                          } else if (params.get('cleverPushSubscriptionIdAlert') === TRUE_STRING) {
                            this.subscriptionManager.isSubscribed().then((subscribed) => {
                              if (subscribed) {
                                this.waitForSubscription().then((subscriptionId) => {
                                  prompt('CleverPush Subscription ID:', subscriptionId);
                                });
                              } else {
                                alert('Dieser Browser ist keinem Push Abonnement zugeordnet.');
                              }
                            });
                          } else if (params.get('cleverPushUnsubscribe') === TRUE_STRING) {
                            const subdomain = (this.config.subdomain || this.config.channelSubdomain);
                            if (subdomain) {
                              this.subscriptionManager.getSubscriptionId().then((subscriptionId) => {
                                this.unsubscribe(() => {
                                  location.href = `https://${subdomain}.${this.config.cleverpushDomain || 'cleverpush.com'}/unsubscribe?subscriptionId=${subscriptionId}${params.get('cleverPushUnsubscribeForce') === TRUE_STRING ? '&force=true' : ''}`;
                                });
                              });
                            }
                          } else if (params.get('cleverPushTestSubscription') === TRUE_STRING) {
                            this.api.testSubscription = true;
                            this.subscriptionManager.sync().then(() => {
                              alert('Subscription has been successfully marked as Test Subscription.');
                            });
                          } else if (importedSubscriptionId) {
                            localStorage.setItem('cleverpush-imported-subscription-id', importedSubscriptionId);

                            this.waitForSubscription().then((subscriptionId) => {
                              if (subscriptionId && subscriptionId !== importedSubscriptionId) {
                                this.api.unsubscribe(importedSubscriptionId);
                                localStorage.removeItem('cleverpush-imported-subscription-id');
                                localStorage.removeItem('cleverpush-onesignal-subscription-id');
                              }
                            });
                          } else if (params.get('cleverPushOptIn') === TRUE_STRING) {
                            let tags;
                            if (params.get('cleverPushTags')) {
                              tags = params.get('cleverPushTags').split(',');
                              const existingText = 'Du hast dieses Thema bereits abonniert';
                              const keepText = 'Klicke auf [OK] um fortzufahren oder auf [Abbrechen] um dieses Thema zu deabonnieren.';
                              const successText = 'Du hast dieses Thema erfolgreich abonniert';

                              const notSubscribed = () => {
                                tags.forEach((tag) => {
                                  this.tagSubscription(tag);
                                });
                                this.subscribe((err) => {
                                  if (err && err.reason === 'denied') {
                                    if (this.subscriptionManager.confirm) {
                                      this.subscriptionManager.confirm.showBackdrop(undefined, 'denied', utilsTranslate('popup.info').formatCleverPush(`<strong>${utilsTranslate('confirm.allowBrowser') || utilsTranslate('confirm.allow')}</strong>`));
                                    }
                                  } else {
                                    alert(successText);
                                  }
                                });
                              };

                              this.subscriptionManager.isSubscribed().then((subscribed) => {
                                if (subscribed) {
                                  this.waitForSubscription().then(() => {
                                    this.subscriptionManager.storageManager.getTags().then((storedTags) => {
                                      if ((storedTags || []).filter((t) => tags.indexOf(t) > -1).length >= tags.length) {
                                        if (!confirm(`${existingText}\n\n${keepText}`)) {
                                          tags.forEach((tag) => {
                                            this.untagSubscription(tag);
                                          });
                                        }
                                      } else {
                                        alert(successText);
                                        tags.forEach((tag) => {
                                          this.tagSubscription(tag);
                                        });
                                      }
                                    });
                                  });
                                } else {
                                  notSubscribed();
                                }
                              }).catch(() => {
                                notSubscribed();
                              });
                            } else {
                              this.subscriptionManager.isSubscribed().then((subscribed) => {
                                if (subscribed) {
                                  this.waitForSubscription().then(() => {
                                    this.subscriptionManager.storageManager.getTopics().then((storedTopics) => {
                                      const channelTopics = (this.config.channelTopics || []).filter((topic) => {
                                        let show = true;
                                        if (show && topic.matchPath && topic.matchPath.length) {
                                          show = new RegExp(topic.matchPath).test(window.parent.location.pathname);
                                        }
                                        if (show && topic.notMatchPath && topic.notMatchPath.length) {
                                          show = !(new RegExp(topic.notMatchPath).test(window.parent.location.pathname));
                                        }
                                        return show;
                                      });

                                      if (this.config.confirmAlertHideChannelTopics || (storedTopics || []).length >= (channelTopics || []).length) {
                                        alert(utilsTranslate('confirm.alreadySubscribed'));
                                      } else {
                                        (this.config.alertLocalization || {}).title = utilsTranslate('confirm.selectTopics');
                                        (this.config.alertLocalization || {}).info = ' ';
                                        (this.config.alertLocalization || {}).allow = utilsTranslate('panel.save');
                                        if (this.confirm) {
                                          this.confirm.subscribedTopics = storedTopics;
                                        }
                                        this.triggerOptIn(true);
                                      }
                                    });
                                  });
                                } else {
                                  this.triggerOptIn(true, (error) => {
                                    if (error && error.reason === 'denied') {
                                      if (this.subscriptionManager.confirm) {
                                        this.subscriptionManager.confirm.showBackdrop(undefined, 'denied', utilsTranslate('popup.info').formatCleverPush(`<strong>${utilsTranslate('confirm.allowBrowser') || utilsTranslate('confirm.allow')}</strong>`));
                                      }
                                    }
                                  });
                                }
                              }).catch(() => {
                                this.triggerOptIn(true, (error) => {
                                  if (error && error.reason === 'denied') {
                                    if (this.subscriptionManager.confirm) {
                                      this.subscriptionManager.confirm.showBackdrop(undefined, 'denied', utilsTranslate('popup.info').formatCleverPush(`<strong>${utilsTranslate('confirm.allowBrowser') || utilsTranslate('confirm.allow')}</strong>`));
                                    }
                                  }
                                });
                              });
                            }
                          } else if (params.get('cleverPushShowBell') === TRUE_STRING) {
                            this.config.hideNotificationBellSubscribed = false;
                            this.config.hideNotificationBellMobile = false;
                            this.config.showNotificationBell = true;
                          }

                          if (params.get('cleverPushReferralSubscriptionId') && !sessionStorage.getItem('cleverpush-referral-subscription-id')) {
                            sessionStorage.setItem('cleverpush-referral-subscription-id', params.get('cleverPushReferralSubscriptionId'));
                          }

                          if (params.get('cleverPushNotificationId')) {
                            this.subscriptionManager.setClickedNotification(params.get('cleverPushNotificationId'));
                            localStorage.setItem('cleverpush-notification-id', params.get('cleverPushNotificationId'));
                            this.subscriptionManager.storageManager.setNotificationRead(params.get('cleverPushNotificationId'));
                          }

                          if (params.get('cleverPushFollowUpCampaignId')) {
                            sessionStorage.setItem('cleverpush-follow-up-campaign-id', params.get('cleverPushFollowUpCampaignId'));
                          }

                          if (params.get('cleverPushJourneyId')) {
                            sessionStorage.setItem('cleverpush-journey-id', params.get('cleverPushJourneyId'));
                          }

                          const cleverPushVoucherCode = params.get('cleverPushVoucherCode');
                          const cleverPushVoucherPoolId = params.get('cleverPushVoucherPoolId');
                          const cleverPushVoucherPool = this.config.voucherPools?.find((voucherPool) => voucherPool._id === cleverPushVoucherPoolId);
                          if (cleverPushVoucherCode) {
                            import('./pageBanner').then(({ default: PageBanner }) => {
                              this.config.cleverPushVoucherCode = cleverPushVoucherCode;
                              const voucherNotificationObj = {
                                title: cleverPushVoucherPool?.title || utilsTranslate('voucherpool.title'),
                                text: cleverPushVoucherPool?.description || utilsTranslate('voucherpool.text'),
                                message: cleverPushVoucherPool?.message || utilsTranslate('voucher.code.copied'),
                              };
                              const followupNotification = new PageBanner(voucherNotificationObj, this.config, this.subscriptionManager, this.api, 'voucher');
                              followupNotification.show();
                            });
                          }
                        }
                      }

                      this.config.clickedFollowUpCampaignId = sessionStorage.getItem('cleverpush-follow-up-campaign-id');
                      this.config.clickedJourneyId = sessionStorage.getItem('cleverpush-journey-id');

                      import('./utils').then(({ initNotificationId }) => {
                        initNotificationId((id) => {
                          this.notificationId = id;
                        });
                      });

                      this.initFollowUpCampaigns();
                      this.initJourneys();

                      // Session Notification Tracking
                      if (this.config.trackNotificationSessions) {
                        try {
                          this.waitForSubscription().then((subscriptionId) => {
                            const notificationId = typeof sessionStorage !== 'undefined' ? localStorage.getItem('cleverpush-notification-id') : undefined;
                            if (notificationId) {
                              this.api.trackSessionImpression(subscriptionId, notificationId);
                            }
                          });
                        } catch (err) {
                          log.warn(err);
                        }
                      }

                      // Optionally set location change interval. No listener is possible, the only way is an interval.
                      // This can be enabled manually by CleverPush if the user is using some JS router (like Angular, React)
                      // and we need to hook into URL changes
                      let currentPathname = location.pathname;
                      if (this.config.detectLocationIntervalEnabled) {
                        setInterval(() => {
                          if (location.pathname !== currentPathname) {
                            log.debug('Location change detected');
                            this.initFollowUpCampaigns();
                            this.initJourneys();
                            this.initConversions();
                            this.initTags();
                            this.initWebBanners();
                            currentPathname = location.pathname;
                          }
                        }, 250);
                      }
                    });
                  });
                };

                domReady().then(initSdk).catch((err) => {
                  callback(err);
                });
              });
            }).catch((compatibilityError) => {
              this.webPushUnsupported = true;

              this.initConfirmAlertFilters(this.config).then(() => {
                // Still apply custom CSS styles to unsupported browser as they might be used for web banners etc.
                if (this.config.customCss) {
                  const node = document.createElement('style');
                  node.innerHTML = this.config.customCss + customCssOverrides;
                  document.body.appendChild(node);
                }
              });

              // page banners are always shown, even if push is not supported
              this.initPageBanners();
              this.initWebBanners();
              this.initWidgets();

              import('./utils').then(({ initHideUnsupported }) => {
                initHideUnsupported();
              });

              compatibilityError.warn = true;
              callback(compatibilityError);
            });
          }).catch((err) => {
            callback(err);
          });
        } else if (isIframe(configParam) || isPopup(configParam) || configParam.env === 'POPUP') {
          this.config = configParam;

          this.initWebpackPublicPath();

          if (typeof URLSearchParams !== 'undefined' && location.search && location.search.length) {
            const params = new URLSearchParams(location.search.slice(1));
            if (params.get('confirmAlertTestId')) {
              this.config.confirmAlertTestId = params.get('confirmAlertTestId');
              this.api.setConfirmAlertTestId(this.config.confirmAlertTestId);
            }
          }

          initSubscriptionManager();

          log.debug('Init done');

          this.initialized = true;
          this.initCalled = false;

          callback(false, true);
        }
      }
    }
  }

  initWebpackPublicPath() {
    if (this.config.staticEndpoint && process.env.NODE_ENV !== 'development') {
      // set the public path to load chunks from dynamically, depending on the staticEndpoint from config
      // eslint-disable-next-line camelcase
      __webpack_public_path__ = `${this.config.staticEndpoint}/sdk/`;
    }
  }

  initConfirmAlertTests = async (configParam) => {
    if (this.config.confirmAlertTestsEnabled && this.config.confirmAlertTests?.length > 0) {
      const availableConfirmAlertTests = [];
      for (const test of this.config.confirmAlertTests) {
        const hasConfirmAlertFilter = test.filters?.length > 0;
        if (!hasConfirmAlertFilter) {
          availableConfirmAlertTests.push(test);
          continue;
        }

        // eslint-disable-next-line no-await-in-loop
        const { checkFilterConditions } = await import('./utils/scriptFunctions');

        // eslint-disable-next-line no-await-in-loop
        if (await Promise.all(test.filters.map((filter) => checkFilterConditions(filter, {}, this, true)))) {
          availableConfirmAlertTests.push(test);
        }
      }

      let test;
      if (this.config.confirmAlertTestId) {
        const testIndex = availableConfirmAlertTests.findIndex((tryTest) => (tryTest || {}).id === this.config.confirmAlertTestId);
        if (testIndex >= 0) {
          test = availableConfirmAlertTests[testIndex];
        }
      }

      if (!test) {
        const defaultPercent = 100 / availableConfirmAlertTests.length;

        const tests = availableConfirmAlertTests.map((currentTest) => ({ ...currentTest, percentage: currentTest.percentage || defaultPercent }));

        let i;
        const randomNr = Math.random();
        let threshold = 0;

        for (i = 0; i < tests.length; i += 1) {
          if (!tests[i].percentage) {
            continue;
          }

          threshold += (tests[i].percentage / 100);
          if (threshold > randomNr) {
            test = tests[i];
            break;
          }

          if (!test) {
            // nothing found based on probability value, so pick element marked with wildcard
            test = tests.filter((tryTest) => !tryTest.percentage);
          }
        }
      }

      if (test && test.config && configParam.env !== 'PREVIEW') {
        log.debug('Split-Test:', test);
        this.config = {
          ...this.config,
          ...test.config,
          confirmAlertTestId: test.id,
          customCss: (test.config.customCss || '') + (this.config.customCss ? this.config.customCss : ''),
          alertLocalization: { ...this.config.alertLocalization, ...test.config.alertLocalization || {} },
        };
        if (!test.alertLocalization) {
          if (!this.config.alertLocalization) {
            this.config.alertLocalization = {};
          }
          this.config.alertLocalization = {
            allow: test.config.alertLocalizationAllow || this.config.alertLocalization.allow,
            deny: test.config.alertLocalizationDeny || this.config.alertLocalization.deny,
            confirmInfo: test.config.alertLocalizationConfirmInfo || this.config.alertLocalization.confirmInfo,
            title: test.config.alertLocalizationTitle || this.config.alertLocalization.title,
            info: test.config.alertLocalizationInfo || this.config.alertLocalization.info
          };
        }
        this.api.setConfirmAlertTestId(this.config.confirmAlertTestId);
      }
    }
  };

  /**
   * Displays the unblock tutorial for the users to give guidance
   * on how to unblock notifications, so a successful opt-in can occur.
   *
   * @function
   */
  displayUnblockTutorial = () => {
    this.config.showSilentPromptTutorial = true;
    this.config.showConfirmAlert = true;
  }

  getWebBanners = (groupId, callback) => {
    if (groupId) {
      const filteredBanners = this.config.webBanners.filter((banner) => banner.group === groupId);
      callback(filteredBanners);
    } else {
      callback(this.config.webBanners);
    }
  }

  importSubscriptionManager(importType, filterType) {
    import(`./subscription/${importType}`).then(({ default: SubscriptionManager }) => {
      this.subscriptionManager = new SubscriptionManager(this.config, this.api, this.trigger.bind(this));
      if (filterType === 'denyCountGreaterThan') {
        this.subscriptionManager.storageManager.setDenyStatus();
      }
    });
  }

  setAuthorizerToken = (authorizerToken) => {
    this.api.authorizerToken = authorizerToken;
  }

  initConfirmAlertFilters = async (configParams) => {
    const { confirmAlertFilters, env } = this.config;
    if (!confirmAlertFilters?.length || env === 'PREVIEW') {
      return;
    }

    const { applyConfirmAlertFilter, checkFilterConditions } = await import('./utils/scriptFunctions');

    for (const filter of confirmAlertFilters) {
      if (checkFilterConditions(filter, configParams, this)) {
        applyConfirmAlertFilter(filter, configParams, this);
      }
    }
  }

  initHttpIframe(config) {
    logMethodCall('initHttpIframe', config);

    this.init(config);
  }

  initWidget(config) {
    logMethodCall('initWidget', config);

    this.init({ ...config, env: 'WIDGET' });
  }

  initWidgets = () => {
    if (this.config.widgets?.length || document.querySelectorAll('.cleverpush-content-button').length) {
      return import('./widget/initWidgets').then(({ initWidgets }) => initWidgets(this));
    }
    return Promise.resolve();
  }

  initWebBanners = () => {
    if (this.config.webBanners?.length || this.config.appDownloadBanner || this.config.homeScreenBanner) {
      return import('./webBanner/initWebBanners').then(({ initWebBanners }) => initWebBanners(this));
    }
    return Promise.resolve();
  }

  showWebBannerById = (bannerId, testId) => import('./webBanner/initWebBanners').then(({ showWebBannerById }) => showWebBannerById(bannerId, testId, this))

  initContentButtons = () => this.initWidgets()

  enableWebBanners = () => {
    if (!this.webBannersDisabled) {
      return;
    }
    this.webBannersDisabled = false;
    this.initWebBanners();
  }

  disableWebBanners = () => {
    this.webBannersDisabled = true;
    /**
     * Enabling / disabling app banners works for exit-intent right now
     * In the future we might need to cache the pending banners into an array and show them once enableAppBanners is called again.
     */
    this.pendingWebBanners = [];

    if (this.elementExitIntentListener) {
      this.elementExitIntentListener.disable();
      this.elementExitIntentListener = null;
    }
  }

  initElementExitIntentListener(elementType, shownElement, elementHTML, element, shownElements, storageKey) {
    import('ouibounce').then(({ default: ouibounce }) => {
      if (this.webBannersDisabled && elementType === WEB_BANNER) {
        return;
      }
      this.elementExitIntentListener = ouibounce(null, {
        aggressive: true,
        callback: async () => {
          import('./utils/matchElementPathAndDomain').then(async ({ matchElementPathAndDomain }) => {
            let show = !shownElement;
            show = await matchElementPathAndDomain(show, element);

            if (show) {
              if (elementType === WEB_BANNER) {
                if (elementHTML.multipleBannersBehaviour === MULTIPLE_BANNER_BEHAVIOUR.PER_SESSION) {
                  sessionStorage.setItem(SESSION_BANNER_DISPLAY_KEY, elementHTML._id);
                }
                if (elementHTML.multipleBannersBehaviour === MULTIPLE_BANNER_BEHAVIOUR.PER_IMPRESSION) {
                  this.multipleBannerOncePerImpressionShown = true;
                }
              }
              this.displayElement(element, shownElements, elementHTML, storageKey, (elementType === WEB_BANNER ? Event.BANNER_SHOWN : Event.WIDGET_SHOWN));
            }
          });
        }
      });
    });
  }

  /**
   * This function will dislay Web Banner and updates local/session storage
   *
   * @function
   * @param {object} webBanner
   * @param {Array.<String>} shownWebBanners
   * @param {object} banner
   * @param {String} storageKey
   */
  displayElement(element, shownElements, elementHTML, storageKey, event) {
    element.show();
    import('./utils/scriptFunctions').then(({ markElementAsShown }) => {
      markElementAsShown(elementHTML, storageKey);
      this.trigger(event, {
        bannerId: elementHTML?._id,
        testId: elementHTML?.testId,
      });
    });
  }

  initTagSwitches = () => {
    if (document.querySelectorAll('.cleverpush-tag-switch').length) {
      import('./tagSwitch').then(({ initTagSwitches }) => {
        initTagSwitches(this);
      });
    }
  }

  initTagButtons = () => {
    if (document.querySelectorAll('.cleverpush-tag-button').length) {
      import('./tagButton/initTagButtons').then(({ initTagButtons }) => {
        initTagButtons(this);
      });
    }
  }

  initTopicButtons = () => {
    if (document.querySelectorAll('.cleverpush-topic-button').length) {
      import('./utils').then(({ initTopicButtons }) => {
        initTopicButtons(this);
      });
    }
  }

  initPageBanners = () => {
    if (this.config.pageBannerNotifications?.length || this.config.pageBannerAdblockEnabled) {
      import('./pageBanner/initPageBanners').then(({ initPageBanners }) => {
        initPageBanners(this);
      });
    }
  }

  initTags = () => {
    if (this.config.channelTags?.length) {
      import('./utils/tags').then(({ initTags }) => {
        initTags(this);
      });
    }
  }

  initAttributes = () => {
    if (this.config.customAttributes?.length) {
      import('./utils/attributes').then(({ initAttributes }) => {
        initAttributes(this);
      });
    }
  }

  initTopics = () => {
    if (this.config.channelTopics?.length) {
      import('./utils/topics').then(({ initTopics }) => {
        initTopics(this);
      });
    }
  }

  initConversions = () => {
    if (this.config.channelEvents?.length) {
      import('./utils/initConversions').then(({ initConversions }) => {
        initConversions(this);
      });
    }
  }

  initFollowUpCampaigns = () => {
    if (this.config.followUpCampaigns?.length) {
      import('./followUp/initFollowUpCampaigns').then(({ initFollowUpCampaigns }) => {
        initFollowUpCampaigns(this);
      });
    }
  }

  triggerBellClick = () => {
    import('./utils/events').then(({ triggerBellClick }) => {
      triggerBellClick(this);
    });
  }

  initJourneys = () => {
    if (this.config.journeys?.length) {
      import('./utils/initJourneys').then(({ initJourneys }) => {
        initJourneys(this.config.journeys, async (journeyId, payload) => { await this.processJourneyElement(journeyId, payload); });
      });
    }
  }

  initTcf() {
    if (!this.config.tcfAutoUnsubscribe) {
      return;
    }

    this.waitForInit().then(() => {
      import('./utils/tcf').then(({ default: initTcf }) => {
        initTcf(this.subscriptionManager);
      });
    });
  }

  initDuplicateSubscriptions = () => {
    const { duplicateChannels } = this.config;
    if (!duplicateChannels || !duplicateChannels.length) {
      return;
    }

    this.subscriptionManager.getSubscriptionId().then((subscriptionId) => {
      if (!subscriptionId) {
        return;
      }

      import('./utils/duplicates').then(({ default: initDuplicates }) => {
        initDuplicates(this.config, this.api, subscriptionId);
      });
    });
  }

  initSpeechbubblePanels = () => {
    if (document.querySelectorAll("a[href='#cleverpush-bell-speechbubble']").length) {
      import('./speechbubblePanel').then(({ initSpeechbubblePanels }) => {
        initSpeechbubblePanels(this);
      });
    }
  }

  initHttpPopup = (config) => {
    import('./utils/http').then(({ initHttpPopup }) => {
      initHttpPopup(config, this);
    });
  }

  initAutoUnsubscribeDuplicateSubscriptions(subscriptionId, iframeSubscriptionId) {
    const { autoUnsubscribeDuplicateSubscriptions } = this.config;
    if (!autoUnsubscribeDuplicateSubscriptions) {
      return;
    }

    if (subscriptionId === iframeSubscriptionId || !iframeSubscriptionId) {
      return;
    }

    import('./utils/autoUnsubscribeDuplicates').then(({ default: initDuplicates }) => {
      initDuplicates(this.config, this.api, this.subscriptionManager, subscriptionId, iframeSubscriptionId);
    });
  }

  getNotifications = async (callback) => {
    const userIsSubscribed = await new Promise((resolve) => {
      this.isSubscribed((isSubscribed) => {
        resolve(isSubscribed);
      });
    });
    const hasNotificationsStoredInBrowserDb = await this.subscriptionManager.hasNotificationsStoredInBrowserDb();

    if (userIsSubscribed && hasNotificationsStoredInBrowserDb) {
      this.getDeliveredNotifications(callback);
      return;
    }

    this.getRemoteNotifications(callback);
  }

  getDeliveredNotifications = (callback) => {
    this.subscriptionManager.getDeliveredNotifications().then((notifications) => {
      callback?.(notifications);
    });
  }

  getRemoteNotifications = (callback) => {
    this.subscriptionManager.getStoredNotifications().then((notifications) => {
      callback?.(notifications);
    });
  }

  showSpeechbubbleInlinePanel(specialLink) {
    if (!this.panel) {
      import('./panel').then(({ default: Panel }) => {
        this.panel = new Panel(this.config, this.subscriptionManager, this.trigger.bind(this), this.api);
        this.panel.showSpeechbubblePanel(specialLink);
      });
    } else {
      this.panel.showSpeechbubblePanel(specialLink);
    }
  }

  getNotificationPermission = () => this.waitForInit().then(() => new Promise((resolve) => {
    if (this.config.ownDomain && location.protocol === 'https:') {
      if (this.browserType === 'safari') {
        if (this.config.safariWebsitePushId) {
          resolve(window.safari.pushNotification.permission(this.config.safariWebsitePushId).permission);
        } else {
          log.debug('Safari website push ID is unknown.');
        }
      } else if (this.browserType === 'facebook' || this.browserType === 'wallet') {
        resolve('default');
      } else {
        resolve(window.Notification.permission);
      }
    } else {
      import('./messenger/command').then(({ default: Command }) => {
        this.iframeMessenger.message(Command.REMOTE_NOTIFICATION_PERMISSION, null, (reply) => {
          const remoteNotificationPermission = reply.data;
          resolve(remoteNotificationPermission);
        });
      });
    }
  }));

  push(item) {
    if (typeof (item) === 'function') {
      item();
    } else if (typeof (item) === 'string') {
      this.push(arguments);
    } else {
      const functionName = item.shift();
      this[functionName].apply(this, item);
    }
  }

  executeFunction() {
    let args = [];
    if (arguments && typeof arguments[0] === 'object') {
      args = arguments[0];
    } else {
      args = arguments;
    }
    const fn = this[args[0]];
    if (typeof fn === 'function') {
      fn.apply(this, Array.prototype.slice.call(args, 1));
    }
  }

  waitForInit() {
    // TODO: find a better way than just not rejecting. But this was causing uncaught promise rejections for init errors, eg. on unsupported browsers, because we use waitForInit many times without .catch()
    return new Promise((resolve) => {
      if (this.initFailed) {
        // reject(this.initError);
      } else if (!this.initialized) {
        this.once(Event.INITIALIZED, resolve);
        // this.once(Event.INITIALIZATION_FAILED, reject);
      } else {
        resolve();
      }
    });
  }

  waitForConfigAvailable() {
    return new Promise((resolve) => {
      if (!this.configLoaded) {
        this.once(Event.CONFIG_AVAILABLE, resolve);
      } else {
        resolve();
      }
    });
  }

  waitForConfirmAvailable() {
    return this.waitForInit().then(() => new Promise((resolve) => {
      if (!this.confirm) {
        this.once(Event.CONFIRM_AVAILABLE, resolve);
      } else {
        resolve();
      }
    }));
  }

  waitForSubscription() {
    return new Promise((resolve, reject) => {
      this.waitForInit().then(() => {
        const resolveId = (retry) => {
          this.subscriptionManager.getSubscriptionId().then((subscriptionId) => {
            if (subscriptionId || retry) {
              resolve(subscriptionId);
            } else {
              this.once(Event.SUBSCRIBED, () => resolveId(true));
            }
          });
        };

        if (this.urlSession) {
          resolveId();
          return;
        }

        this.subscriptionManager.isSubscribed().then((isSubscribed) => {
          if (isSubscribed) {
            resolveId();
          } else {
            this.once(Event.SUBSCRIBED, resolveId);
          }
        });
      }).catch(reject);
    });
  }

  waitForBell() {
    return this.waitForInit().then(() => new Promise((resolve) => {
      if (this.bell) {
        resolve();
      } else {
        this.once(Event.BELL_READY, resolve);
      }
    }));
  }

  isSubscribed = (callback) => this.waitForInit().then(() => this.subscriptionManager.isSubscribed().then((isSubscribed) => callback(isSubscribed))).catch(() => callback(false))

  getSubscriptionId = (callback) => this.waitForInit().then(() => {
    this.subscriptionManager.isSubscribed().then((isSubscribed) => {
      if (isSubscribed) {
        this.subscriptionManager.getSubscriptionId().then((subscriptionId) => {
          callback(subscriptionId);
        });
      } else {
        callback(false);
      }
    });
  }).catch(() => {
    callback(false);
  });

  subscribe = (callback) => {
    if (this.initialized) {
      return this.subscriptionManager.subscribe().then(() => this.subscriptionManager.getSubscriptionId().then((subscriptionId) => callback(false, subscriptionId))).catch((error) => callback(error));
    }
    return this.waitForInit().then(() => this.subscriptionManager.subscribe().then(() => this.subscriptionManager.getSubscriptionId().then((subscriptionId) => callback(false, subscriptionId)))).catch((error) => callback(error));
  }

  unsubscribe = (callbackParam, notManuallyParam) => {
    logMethodCall('unsubscribe');

    const callback = typeof callbackParam === 'function' ? callbackParam : (err) => {
      if (err) {
        log.error(err.stack || err.message || err);
      }
    };

    const notManually = typeof notManuallyParam === 'boolean' ? notManuallyParam : false;

    this.waitForInit().then(() => {
      this.subscriptionManager.unsubscribe(notManually).then(() => {
        callback(false, true);
      }).catch((err) => {
        log.error(err);
        callback(false);
      });
    });
  }

  hasTag = (tagId, callback) => {
    logMethodCall('hasTag', tagId);

    this.waitForInit().then(() => {
      if (tagId) {
        this.subscriptionManager.storageManager.hasTag(tagId).then((hasTag) => {
          if (typeof callback === 'function') {
            callback(hasTag);
          }
        });
      } else {
        log.error('tag id not specified');
      }
    });
  }

  setTopics = (topicIds, callback) => {
    import('./utils/topics').then(({ setTopics }) => {
      setTopics(this, topicIds, callback);
    });
  }

  getTopics = (callback) => {
    import('./utils/topics').then(({ getTopics }) => {
      getTopics(this, callback);
    });
  }

  tagSubscription = (tagId, callback) => {
    import('./utils/tags').then(({ tagSubscription }) => {
      tagSubscription(this, tagId, callback);
    });
  }

  untagSubscription = (tagId, callback) => {
    import('./utils/tags').then(({ untagSubscription }) => {
      untagSubscription(this, tagId, callback);
    });
  }

  hasAttribute = (attributeId, attributeValue, callback) => {
    import('./utils/attributes').then(({ hasAttribute }) => {
      hasAttribute(this, attributeId, attributeValue, callback);
    });
  }

  setAttribute = (attributeId, valueParam, callback) => {
    import('./utils/attributes').then(({ setAttribute }) => {
      setAttribute(this, attributeId, valueParam, callback);
    });
  }

  incAttribute = (attributeId, incParam, callback) => {
    import('./utils/attributes').then(({ incAttribute }) => {
      incAttribute(this, attributeId, incParam, callback);
    });
  }

  getAttribute = (attributeId, callback) => {
    import('./utils/attributes').then(({ getAttribute }) => {
      getAttribute(this, attributeId, callback);
    });
  }

  pushAttributeValue = (attributeId, valueParam, optionsParam, callbackParam) => {
    import('./utils/attributes').then(({ pushAttributeValue }) => {
      pushAttributeValue(this, attributeId, valueParam, optionsParam, callbackParam);
    });
  }

  pullAttributeValue = (attributeId, valueParam, callback) => {
    import('./utils/attributes').then(({ pullAttributeValue }) => {
      pullAttributeValue(this, attributeId, valueParam, callback);
    });
  }

  trigger(eventName, data) {
    if (data || data === false) {
      log.debug(`%c${eventName?.toUpperCase()}:`, getConsoleStyle('event'), data);
    } else {
      log.debug(`%c${eventName?.toUpperCase()}`, getConsoleStyle('event'));
    }

    if (isBrowser()) {
      if (eventName === Event.INITIALIZED) {
        if (this.initialized) {
          return;
        }
        this.initialized = true;
      }
      this.emit(eventName, data);
    }
  }

  hidePanel() {
    if (this.panel) {
      this.panel.hide();
    }
  }

  translate(key) {
    return utilsTranslate(key);
  }

  processJourneyElement(journeyId, payload) {
    return import('./utils/triggerJourneysAndFollowUps').then(({ prepareJourneyOrFollowUpTriggers }) => {
      prepareJourneyOrFollowUpTriggers({ _id: journeyId, ...payload }, this.triggerFollowUpEvent, this.triggerJourneyEvent, false);
      return new Promise((resolve) => setTimeout(resolve, 100));
    });
  }

  /**
   * Deprecated method for conversion event tracking, has been renamed to trackEvent
   */
  trackConversion = (eventId, amount, currency) => this.trackEvent(eventId, { amount, currency })

  trackEvent = (eventId, properties) => {
    import('./utils/events').then(({ trackEvent }) => {
      trackEvent(this, eventId, properties);
    });
  }

  triggerFollowUpEvent = (followUpCampaignId, eventName, eventParamsParam) => {
    import('./utils/events').then(({ triggerFollowUpEvent }) => {
      triggerFollowUpEvent(this, followUpCampaignId, eventName, eventParamsParam);
    });
  }

  triggerJourneyEvent = (journeyId, eventName, eventParamsParam, type) => {
    import('./utils/events').then(({ triggerJourneyEvent }) => {
      triggerJourneyEvent(this, journeyId, eventName, eventParamsParam, type);
    });
  }

  hasBannerWithConversionEvent(conversionEventId) {
    const bannersWithEvent = (this.webBanners || []).filter((banner) => banner.webBanner?.conversionEvents.includes(conversionEventId));
    return !!bannersWithEvent;
  }

  isLastCheckExpired(lastConversionTrackedAt, additionalDelayToCheck) {
    return (lastConversionTrackedAt && !isNaN(lastConversionTrackedAt) && (parseInt(lastConversionTrackedAt, INT_RADIX) + additionalDelayToCheck) < Date.now()) || this.config.trackConversionsWithoutFrequencyCapping;
  }

  generateWalletPass(walletPassId, options, callback) {
    return new Promise((resolve, reject) => {
      this.api.generateWalletPass(walletPassId, options).then((result) => {
        if (typeof callback === 'function') {
          callback(null, result);
        }
        resolve(result);
      }).catch((err) => {
        if (typeof callback === 'function') {
          callback(err, null);
        }
        reject(err);
      });
    });
  }

  /**
   * checks if the given TriggerType is valid or not
   *
   * @param  {string} triggerType Trigger Type fetched from url
   * @return  {string | null} TriggerType | Null
   */
  validateUrlTriggerType(triggerType) {
    if (Object.values(PANEL_TABS).includes(triggerType)) {
      return triggerType;
    }
    return null;
  }
}
